<template>
  <div id="putIntoHistogramId" style="width: 100%; height: 600px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'PutIntoHistogram',
  props: {
    projectPutIntoInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    projectPutIntoInfo: {
      deep: true,
      immediate: true,
      handler: function (info) {
        if (info) {
          this.init(info)
        } else {
          this.init({})
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    // 项目收入统计
    init(info = {}) {
      function truncateToOneDecimal(num) {
        // 将数字转换为字符串，找到小数点的位置
        let str = num.toString()
        let dotIndex = str.indexOf('.')
        // 如果没有小数点，直接返回字符串
        if (dotIndex === -1) {
          return str
        }
        // 截取小数点后一位
        return str.slice(0, dotIndex + 2) // +2 是包括小数点和一位数字
      }
      this.$nextTick(() => {
        var chartDom = document.getElementById('putIntoHistogramId')
        var myChart = echarts.init(chartDom)
        var option
        const userArr = info.projectNumberList || []
        const huorArr = info.projectMaHourList || []
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function (params) {
              const userName = params[0].name // 获取 x 轴名称
              const userValue = Number(params[0].value).toLocaleString()
              const huorValue = Number(params[1].value).toLocaleString()
              return `${userName}:<br/>投入： ${userValue} 人<br/>投入： ${huorValue}小时 `
            },
          },
          legend: {
            data: ['投入人数', '投入工时'],
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true,
              },
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              axisPointer: {
                type: 'shadow',
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '投入人数（个）',
              position: 'left',
              alignTicks: true,
              max: 300,
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#5470C6',
                },
              },
            },
            {
              type: 'log',
              name: '投入工时（H）',
              position: 'right',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#91CC75',
                },
              },
              min: 1,
              axisLabel: {
                formatter: val => {
                  if (val === 1) {
                    return 0
                  }
                  if (val >= 10000) {
                    return (val / 10000).toFixed(1) + 'W'
                  }
                  if (val >= 1000) {
                    return (val / 1000).toFixed(1) + 'K'
                  }
                  return val
                },
              },
            },
          ],
          series: [
            {
              name: '投入人数',
              type: 'bar',
              yAxisIndex: 0,
              label: {
                fontWeight: 'bold',
                show: true, // 显示标签
                position: 'top', // 标签显示在柱子顶部
                // formatter: '{c}', // 标签内容为柱子的数值
                formatter: function (params) {
                  const value = params.value
                  if (value == 0) {
                    return ''
                  }
                  return value
                },
                color: '#000', // 标签颜色
                fontSize: 12, // 标签字体大小
              },
              data: userArr,
            },
            {
              name: '投入工时',
              type: 'bar',
              yAxisIndex: 1,
              label: {
                show: true,
                position: 'top',
                formatter: function (params) {
                  const value = params.value
                  if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W'
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K'
                  }
                  return value
                },
                fontSize: 12,
                fontWeight: 'bold',
              },
              data: huorArr,
            },
          ],
        }

        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
